<script>
import axios from 'axios'

export default {
  data() {
    return {
      otp:'',
      isLoading: false,
    };
  },
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handleOnComplete(value) {
      this.isLoading = true;
      axios
        .post("/v1/otp-logins", {
          user_email: JSON.parse(localStorage.getItem("user")).user_email,
          code_otp: value,
        })
        .then((res) => {
          if (res.data.success == true) {
            // console.log(res)}
            localStorage.setItem("key", res.data.jwt);
            this.isLoading = false;
            this.$swal.fire({
              icon: 'success',
              title: 'Login Success',
              showConfirmButton: false,
              timer: 1000
            }).then(()=>{
              this.$router.push({ name: "home" });
            })
          } else {
            this.isLoading = false;
            this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.data.message,
            })
          }
        })
    },
    // handleOnComplete(value) {
    //   console.log('OTP completed: ', value);
    // },
    // handleOnChange(value) {
    //   console.log('OTP changed: ', value);
    // },
  },
};
</script>

<template>
  <div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-12">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="otp">
                    <div class="login-box">
                      <div class="text-center">
                        <div>
                          <div class="logo">
                            <img src="@/assets/images/logo-2.png" height="50" alt="logo" />
                          </div>
                        </div>

                        <h4 class="font-size-18 mt-4 titles">Enter OTP</h4>
                      </div>

                      <div class="p-2 mt-5">
                        <form class="form-horizontal">
                          <div class="form-group auth-form-group-custom mb-4" >
                            <!-- <i class="ri-lock-2-line auti-custom-input-icon"></i> -->
                            <!-- <input
                              type="text"
                              id="otp"
                              v-model="otp"
                              class="form-control"
                              placeholder="Enter OTP"
                              maxlength="6"
                              minlength="6"
                              required
                              @keypress="isNumber"
                            /> -->
                            <v-otp-input
                              ref="otpInput"
                              input-classes="otp-input"
                              separator="-"
                              :num-inputs="6"
                              :should-auto-focus="true"
                              :is-input-num="true"
                              @on-complete="handleOnComplete"
                            />
                          </div>

                          <!-- <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >Log In</button>
                          </div> -->
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          © 2022 Adaremit
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* .titles{
  margin-bottom: -2rem;
} */

</style>
<style lang="scss">
  .otp-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    &.error {
      border: 1px solid red !important;
    }
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>